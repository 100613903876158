<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button" >
                <div class="h-b"><el-button type="primary" size="small" @click="add"  v-right-code="'Sysexternalsystem:Edit'">新增</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="externalSystemListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams"
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='ExternalIdentity'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadExternalSystemPageList", () => this.reloadPageList());
            this.Event.$on("onAdd", () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {},
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/sysexternalsystem/searchexternalsystemlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;
                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                var _this=this;
                this.$ajax.send("omsapi/sysexternalsystem/getexternalsystembyid", "get", { id: row.ExternalSystemID }, (data) => {
                    this.Event.$emit("clearEditExternalSystemForm");
                    _this.setRowEditTag(data.Result.ComExternalParamList);
                    _this.setRowEditTag(data.Result.ComExternalMappingList);
                    _this.setRowEditTag(data.Result.ExternalParamList);
                    _this.setRowEditTag(data.Result.ExternalMappingList);
                    this.onChangeEditDataSource(data.Result);
                });
            },
            setRowEditTag: function (datasource) {
                if (!datasource) return;

                for (let i in datasource) {
                    if (typeof datasource[i] != 'object') continue;

                    datasource[i].RowEdit = false;
                }
            },
            add() {
                this.$ajax.send("omsapi/sysexternalsystem/getemptyexternalsystem", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },   
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
        },
    }
</script>
<style>

</style>